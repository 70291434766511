import React from "react";
import { useForm } from "react-hook-form";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      // Send the data to your backend
      const response = await fetch(
        process.env.REACT_APP_SERVER + "/utils/mailing",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();

      // Check if the request was successful
      if (response.ok) {
        alert("Your message has been sent successfully!");
      } else {
        alert(responseData.error);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An unexpected error occurred.");
    }
  };

  return (
    <div id="contact" className="contact-form-container">
      <h2 className="text-3xl">Laissez moi un message</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="contact-form text-black"
      >
        {/* First Name */}
        <div className="flex flex-row justify-stretch gap-4 flex-wrap">
          <div className="form-group flex-1">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstname"
              {...register("firstname", { required: "First name is required" })}
              className="form-input"
            />
            {errors.firstName && (
              <p className="error">{errors.firstName.message}</p>
            )}
          </div>

          {/* Last Name */}
          <div className="form-group flex-1">
            <label htmlFor="lastname">Last Name</label>
            <input
              type="text"
              id="lastName"
              {...register("lastname", { required: "Last name is required" })}
              className="form-input"
            />
            {errors.lastName && (
              <p className="error">{errors.lastName.message}</p>
            )}
          </div>
        </div>
        {/* Email */}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                message: "Please enter a valid email address",
              },
            })}
            className="form-input"
          />
          {errors.email && <p className="error">{errors.email.message}</p>}
        </div>

        {/* Subject */}
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            {...register("subject", { required: "Subject is required" })}
            className="form-input"
          />
          {errors.subject && <p className="error">{errors.subject.message}</p>}
        </div>

        {/* Message */}
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            {...register("message", { required: "Message is required" })}
            className="form-input min-h-[150px]"
          ></textarea>
          {errors.message && <p className="error">{errors.message.message}</p>}
        </div>

        <button type="submit" className="submit-btn text-xl bg-white">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
