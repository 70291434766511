import React from "react";

export default function TopContactBar() {
  return (
    <div className="bg-gray-700 flex items-center justify-center gap-4 py-2 text-white">
      <a href="mailto:mickadam@protonmail.com"> mickadam@protonmail.com</a> |{" "}
      <a href="tel:+41799471613"> +41 79 947 16 13</a>
    </div>
  );
}
