import React from "react";
import YouTubeCarousel from "./YoutubeCarousel";

const Header = () => {
  return (
    <header className="header flex flex-col items-center justify-center bg-gradient-to-r from-blue-500 to-purple-500 text-white text-center p-4">
      <h1 className="text-5xl font-bold">Bienvenue sur mon Portfolio</h1>

      <p className="mt-4 text-2xl">
        C'est ici que votre imagination prend vie ! 😁
      </p>
      <YouTubeCarousel />
    </header>
  );
};

export default Header;
