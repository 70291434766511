import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const YouTubeCarousel = () => {
  const videos = [
    {
      link: "https://www.youtube.com/embed/f4wcAdhnAvA",
      text: "Immobilier",
    },
    {
      link: "https://www.youtube.com/embed/AfQfUPinW5o",
      text: "Inspections et travaux aériens",
    },
    {
      link: "https://www.youtube.com/embed/_FCgpRuF7Hg",
      text: 'Mon "Social Media Kit"',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className="my-4 text-white">
      <div className="youtube-carousel">
        {videos.map((video, index) => (
          <div
            key={index}
            className="video-slide flex flex-col items-stretch   px-8 p-4 gap-4"
          >
            <h1 className="text-xl bg-white min-w-[380px] shadow-lg px-8 py-2 text-gray-900 font-bold  rounded-full">
              {video.text}
            </h1>
            <iframe
              className="margin-auto w-[380px] h-[300px]"
              src={video.link}
              title={`YouTube Video ${index + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YouTubeCarousel;
