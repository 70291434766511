import { useEffect, useState } from "react";

function useSession() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch session data
  const fetchSession = async () => {
    setLoading(true);
    try {
      const request = await fetch(process.env.REACT_APP_SERVER + "/session", {
        credentials: "include", // Include cookies
      });

      const response = await request.json();
      setSession(response.sessionData);
    } catch (err) {
      console.log(err.message);
      setError("Failed to fetch session");
    } finally {
      setLoading(false);
    }
  };

  // Update session data
  const updateSession = async (data) => {
    try {
      const response = await fetch(process.env.REACT_APP_SERVER + "/session", {
        method: "POST",
        credentials: "include", // Include cookies
      });
      setSession(response.data.sessionData);
    } catch (err) {
      setError("Failed to update session");
    }
  };

  useEffect(() => {
    fetchSession();
  }, []);

  return { session, loading, error, updateSession };
}

export default useSession;
