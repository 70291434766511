import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Newsletter from "./components/Newletter";
import PricingSection from "./components/PriceSection";
import TopContactBar from "./components/TopContactBar";
import WhoAm from "./components/WhoAm";
import useSession from "./hooks/useSession";

function App() {
  const { session, loading, error, updateSession } = useSession();

  if (loading) return <p>Loading session...</p>;
  if (error) return <p>Error: {error}</p>;
  return (
    <div className="App min-w-[350px]">
      <TopContactBar />
      <Navbar />
      <Header />
      <WhoAm />
      <PricingSection />
      <Newsletter />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
