import React from "react";

const Navbar = () => {
  return (
    <nav className="flex flex-wrap justify-end items-center p-4 bg-white text-white">
      <div className="flex-1"></div>

      <img src="/portrait5.png" alt="Logo" className="h-32" />
      <div className="flex-1"></div>
    </nav>
  );
};

export default Navbar;
