import React from "react";

const SocialButtons = () => {
  const socialLinks = [
    {
      name: "Instagram",
      url: "https://www.instagram.com/mickadamatha/",
      icon: "/insta.png",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/mickael-da-matha-sant-anna-a3b657bb/",
      icon: "/linkedin.png",
    },
    {
      name: "Youtube",
      url: "https://www.youtube.com/@mickaeldprod",
      icon: "/youtube.png",
    },
  ];

  return (
    <div className="flex justify-center space-x-4  gap-10">
      {socialLinks.map((social) => (
        <a
          key={social.name}
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center w-8 h-8 text-white rounded-full transition"
          title={social.name}
        >
          <img src={social.icon} height={30} />
        </a>
      ))}
    </div>
  );
};

export default SocialButtons;
