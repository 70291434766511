import React from "react";

const PricingSection = () => {
  return (
    <section className="pricing-section  bg-gray-700 ">
      <div className="pricing-container">
        <h2 className="pricing-title">Pricing</h2>
        <div className="pricing-cards">
          {/* Standard Real Estate Sale */}
          <div className="pricing-card  flex flex-col">
            <h3 className="plan-title text-gray-700">Pack IMMO</h3>

            <p className="plan-description">
              Le pack "All Inclusive" pour mettre en lumière vos annonces sans
              surprise.
            </p>
            <div className="flex-1"></div>
            <p className="price">599 CHF</p>
          </div>
        </div>
      </div>
      <a
        href="#contact"
        className="cta-button w-50 bg-white  text-center text-gray-800 mt-4 min-w-[380px] "
      >
        CONTACTEZ-MOI POUR EN SAVOIR PLUS
      </a>
    </section>
  );
};

export default PricingSection;
