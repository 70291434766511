import React from "react";
import { useForm } from "react-hook-form";

const Newsletter = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_SERVER + "/utils/newsletter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Inclure les credentials
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de l'inscription à la newsletter");
      }

      const result = await response.json();
      alert("Un grand merci ! Inscription réussie !");
    } catch (error) {
      alert("Erreur:", error);
    }
  };

  return (
    <div className="bg-gray-300 p-4 flex flex-col items-center justify-start ">
      <h2 className="w-full">
        Inscrit toi à ma Newsletter, pour être au courant mes dernières
        réalisations et de mes offres
      </h2>
      <form
        className="flex flex-row items-center gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          id="email"
          className="form-input flex-1   max-w-[600px]"
          type="email"
          {...register("email", {
            required: "Email est requis",
            pattern: { value: /^\S+@\S+$/i, message: "Email invalide" },
          })}
        />
        {errors.email && <p>{errors.email.message}</p>}

        <button className="btn btn-dark" type="submit">
          S'inscrire
        </button>
      </form>
    </div>
  );
};

export default Newsletter;
