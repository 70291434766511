import React from "react";

const WhoAm = () => {
  return (
    <section id="who-am-i" className=" bg-white text-gray-800 ">
      <div className="flex flex-row flex-wrap gap-4 p-4 justify-center items-center bg-gray-400">
        <img src="/portrait.jpg" className="h-96" />
        <div className="flex-1  min-w-[350px]  text-center flex flex-col">
          <p className=" text-justify mb-4 text-xl"> Bonjour 👋 </p>

          <p className="text-lg text-justify mb-4 ">
            Alliez technologie et créativité pour donner vie à vos projets. Avec
            une véritable passion pour le digital, je m'engage à transformer vos
            idées en récits captivants grâce à des outils performants et un
            savoir-faire éprouvé.
          </p>

          <p className="text-lg text-justify mb-4 ">
            À l'écoute de vos besoins et attentif à vos contraintes, je propose
            des solutions sur mesure qui s’adaptent parfaitement à vos attentes.
            Que ce soit avec un drone, une caméra ou en post-production, chaque
            détail est soigné pour vous garantir un résultat à la hauteur de vos
            ambitions.
          </p>

          <p className="text-lg text-justify mb-4 ">
            Envie de collaborer sur un projet ? Contactez-moi pour discuter de
            vos idées et découvrir comment nous pouvons les concrétiser
            ensemble. Ensemble, créons quelque chose d'exceptionnel !
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhoAm;
