import React from "react";
import SocialButtons from "./SocialButtons";

const Footer = () => {
  return (
    <footer className="py-6 bg-gray-100 text-white text-center">
      <SocialButtons />
      <p className="text-gray-900 mt-2">
        &copy; 2024 Mickael Da matha sant'anna. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
